import { Autocomplete } from "@react-google-maps/api";
import React from "react";
import Button from "./Button";
import Input from "./Input";
import geocode from "react-geocode";
import usestepStore from "../store/step";
import useRouteStore from "../store/route";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import { MapPinIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import logo from "../assets/chhotaHathi.png";
import axios from "axios";

const BookingForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      source: "",
      destination: "",
      stops: [],
      returnJourney: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stops",
  });

  const { current, setStep } = usestepStore((state) => ({
    current: state.current,
    setStep: state.setStep,
  }));

  const { setRoute, setDirection } = useRouteStore((state) => ({
    setRoute: state.setRoute,
    setDirection: state.setDirection,
  }));

  const checkLocationAccess = () => {
    if (!navigator.geolocation) {
      console.log("Location access failed!");
      return;
    }
    navigator.geolocation.getCurrentPosition(accessCurrentLocation, (error) => {
      console.log("Location access failed!");
    });
  };

  const accessCurrentLocation = (position) => {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;

    geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);
    geocode.fromLatLng(lat, lng).then(
      (response) => {
        setValue("source", response.results[0].formatted_address);
      },
      (error) => {
        console.log(error);
        toast.error("Location detection failed!");
      }
    );
  };

  const calculateRoute = async (data) => {
    setDirection(null);
    // Convert stops to waypoints for the Directions API
    const waypoints = data.stops
      .filter((stop) => stop?.name?.trim() !== "")
      .map((stop) => ({ location: stop.name }));

    if (!data.source || !data.destination) {
      toast.error("Please provide both source and destination");
      return;
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    try {
      const results = await directionsService.route({
        origin: data.source,
        destination: data.destination,
        waypoints: waypoints,
        optimizeWaypoints: true,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });

      setDirection(results);

      const legs = results.routes[0].legs;
      if (!legs || legs.length === 0) {
        toast.error("No route found.");
        return;
      }

      // Calculate total distance and duration
      let totalDistanceValue = 0; // in meters
      let totalDurationValue = 0; // in seconds
      legs.forEach((leg) => {
        totalDistanceValue += leg.distance.value;
        totalDurationValue += leg.duration.value;
      });

      // Convert total distance and duration to readable formats
      const totalDistanceKm = totalDistanceValue / 1000;
      const totalDistanceText = totalDistanceKm.toFixed(2) + " km";
      const durationInMins = Math.ceil(totalDurationValue / 60);
      const totalDurationText = durationInMins + " mins";

      // Extract coordinates for source and destination
      const from = {
        lat: legs[0].start_location.lat(),
        lng: legs[0].start_location.lng(),
      };
      const lastLeg = legs[legs.length - 1];
      const to = {
        lat: lastLeg.end_location.lat(),
        lng: lastLeg.end_location.lng(),
      };

      // Extract intermediate stops:
      // All legs except the last one end in a stop or intermediate point.
      // Note: If there were no stops, legs.length would be 1 (just source->destination).
      const stopsArray = [];
      for (let i = 0; i < legs.length - 1; i++) {
        const stopLat = legs[i].end_location.lat();
        const stopLng = legs[i].end_location.lng();
        stopsArray.push({ lat: stopLat, lng: stopLng });
      }

      setRoute({
        distance: totalDistanceText,
        duration: totalDurationText,
        distanceValue: totalDistanceValue,
        from: from,
        to: to,
        stops: stopsArray, // Intermediate stops
        rawData: {
          source: data.source,
          destination: data.destination,
          stops: data.stops.map((s) => s.name), // Original stops entered by user
          returnJourney: data.returnJourney, // boolean
        },
      });

      setStep("Book");
    } catch (e) {
      console.log(e);
      toast.error(
        e.code === "ZERO_RESULTS"
          ? "No route could be found between the origin and destination."
          : "Something went wrong"
      );
    }
  };

  if (current === "Form")
    return (
      <div className="absolute top-24 left-7">
        <div className="bg-white py-6 px-4 w-80 rounded-lg shadow-lg border border-gray-200 hover:shadow-xl transition">
          <div className="flex justify-center mb-4">
            <img src={logo} alt="Chhota Hathi" className="h-16 w-auto" />
          </div>
          
          {/* Source Input */}
          <div className="mb-4">
            <Autocomplete>
              <Input
                error={errors.source}
                {...register("source", {
                  required: {
                    value: true,
                    message: "Please enter a source",
                  },
                })}
                label="Source *"
                endIcon={
                  <MapPinIcon
                    className="w-6 h-6 mr-2 text-gray-600 cursor-pointer hover:text-gray-800 transition"
                    onClick={checkLocationAccess}
                  />
                }
              />
            </Autocomplete>
            {errors.source && (
              <p className="text-red-500 text-sm mt-1">{errors.source.message}</p>
            )}
          </div>

          {/* Multiple Stops Section */}
          {fields.map((field, index) => (
            <div className="mb-4" key={field.id}>
              <div className="flex items-center">
                <Autocomplete className="flex-1">
                  <Input
                    {...register(`stops.${index}.name`, {
                      required: {
                        value: true,
                        message: "Please enter a stop",
                      },
                    })}
                    label={`Stop ${index + 1}`}
                  />
                </Autocomplete>
                <TrashIcon
                  onClick={() => remove(index)}
                  className="w-6 h-6 ml-2 text-red-500 cursor-pointer hover:text-red-600 transition"
                />
              </div>
            </div>
          ))}

          <div className="mb-4 flex items-center space-x-2">
            <Button
              type="button"
              className="flex items-center space-x-1 text-sm py-1 px-2 bg-blue-700 hover:bg-gray-200 text-gray-700 rounded"
              onClick={() => append({ name: "" })}
            >
              <PlusCircleIcon className="w-5 h-5" />
              <span>Add Stop</span>
            </Button>
          </div>

          {/* Destination Input */}
          <div className="mb-4">
            <Autocomplete>
              <Input
                error={errors.destination}
                {...register("destination", {
                  required: {
                    value: true,
                    message: "Please enter a destination",
                  },
                })}
                label="Destination *"
              />
            </Autocomplete>
            {errors.destination && (
              <p className="text-red-500 text-sm mt-1">
                {errors.destination.message}
              </p>
            )}
          </div>

          {/* Return Journey Checkbox */}
          <div className="mb-4 flex items-center space-x-2">
            <input
              type="checkbox"
              {...register("returnJourney")}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label className="text-sm text-gray-700">
              Book Return Journey
            </label>
          </div>

          <Button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded-md transition"
            onClick={handleSubmit(calculateRoute)}
          >
            Check Fare
          </Button>
        </div>
      </div>
    );
};

export default BookingForm;
