// components/BookingDetails.js
import React, { useEffect, useState, useCallback } from "react";
import { GoogleMap, Marker, DirectionsRenderer, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const BookingDetails = ({ booking }) => {
  const [directions, setDirections] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 12.9496225, lng: 77.6248433 }); // Default center

  useEffect(() => {
    console.log(booking, "booking");
  }, [booking]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY, // Replace with your API key
    libraries: ["places"],
  });

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Assigned";
      case 2:
        return "Cancelled";
      case 3:
        return "Completed";
      case 4:
        return "Cancelled";
      default:
        return "Unknown";
    }
  };

  const fetchDirections = useCallback(() => {
    if (!booking) return;

    const waypoints = booking?.other?.stops?.map((stop) => ({
      location: { lat: stop.lat, lng: stop.lng },
      stopover: true,
    }));

    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: { lat: booking.fromLocationLat, lng: booking.fromLocationLng },
        destination: { lat: booking.toLocationLat, lng: booking.toLocationLng },
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK && result) {
          setDirections(result);
          // Set map center to origin
          setMapCenter({
            lat: booking.fromLocationLat,
            lng: booking.fromLocationLng,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }, [booking]);

  useEffect(() => {
    if (isLoaded && booking) {
      fetchDirections();

      // Setup markers
      const allPoints = [
        {
          position: { lat: booking.fromLocationLat, lng: booking.fromLocationLng },
          label: "A",
          title: "From",
        },
        ...booking?.other?.stops?.map((stop, index) => ({
          position: { lat: stop.lat, lng: stop.lng },
          label: String.fromCharCode(66 + index), // B, C, D, etc.
          title: `Stop ${index + 1}`,
        })),
        {
          position: { lat: booking.toLocationLat, lng: booking.toLocationLng },
          label: String.fromCharCode(66 + booking.other.stops.length),
          title: "To",
        },
      ];

      setMarkers(allPoints);
    }
  }, [isLoaded, booking, fetchDirections]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  if (!booking) {
    return null;
  }

  return (
    <div className="p-6 space-y-6">
      <h2 className="text-2xl font-bold mb-4">Booking Details</h2>
      
      {/* Booking Information */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-semibold">Basic Information</h3>
          <div className="mt-2 space-y-2">
            <p><strong>Booking ID:</strong> {booking.id}</p>
            <p><strong>Customer:</strong> {booking?.customer?.name} ({booking?.customer?.email}, {booking.customer?.mobile})</p>
            <p><strong>Driver:</strong> {booking?.driver?.user?.name} ({booking.driver?.user?.email}, {booking?.driver?.user?.mobile})</p>
            <p><strong>Vehicle Type:</strong> {booking.vehicleType.charAt(0).toUpperCase() + booking.vehicleType.slice(1)}</p>
            <p><strong>Distance:</strong> {(booking.distance / 1000).toFixed(2)} km</p>
            <p><strong>Duration:</strong> {booking.duration}</p>
            <p><strong>Fare Estimate:</strong> Rs. {booking.fareEstimate}</p>
            <p><strong>Status:</strong> <span className={`px-2 py-1 rounded ${getStatus(booking.status) === "Cancelled" ? "bg-red-500 text-white" : getStatus(booking.status) === "Completed" ? "bg-green-500 text-white" : "bg-yellow-500 text-white"}`}>{getStatus(booking.status)}</span></p>
            <p><strong>Created At:</strong> {new Date(booking.createdAt).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(booking.updatedAt).toLocaleString()}</p>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold">Locations</h3>
          <div className="mt-2 space-y-2">
            <p><strong>From:</strong> {booking.fromLocationName}</p>
            <p><strong>To:</strong> {booking.toLocationName}</p>
            {booking?.other?.stops?.length > 0 && (
              <div>
                <strong>Stops:</strong>
                <ul className="list-disc list-inside">
                  {booking?.other?.stops?.map((stop, index) => (
                    <li key={index}>{`Stop ${index + 1}: (${stop.lat}, ${stop.lng})`}</li>
                  ))}
                </ul>
              </div>
            )}
            {booking.other?.returnJourney && (
              <p><strong>Return Journey:</strong> Yes</p>
            )}
          </div>
        </div>
      </div>

      {/* Google Map */}
      <div className="w-full h-96">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={12}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          {/* Directions Renderer */}
          {directions && <DirectionsRenderer directions={directions} />}

          {/* Markers */}
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.position}
              label={marker.label}
              title={marker.title}
            />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default BookingDetails;
