// components/Popup.js
import React, { useState } from "react";

const Popup = ({ onSubmit, onClose }) => {
  const [driverName, setDriverName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (driverName) {
      onSubmit(driverName);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h3>Assign Driver</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Driver Name"
            value={driverName}
            onChange={(e) => setDriverName(e.target.value)}
            required
          />
          <button type="submit">Assign</button>
        </form>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
