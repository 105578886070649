import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useJsApiLoader, GoogleMap, DirectionsRenderer, Marker } from "@react-google-maps/api";
import useRouteStore from "./store/route";
import { ToastContainer } from "react-toastify";
import Book from "./components/Book";
import BookingForm from "./components/BookingForm";
import {jwtDecode} from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import BookingList from "./components/BookingList";
import Popup from "./components/Popup";
import axios from 'axios';
import YourBookings from "./components/YourBookings";

// Login Form component
const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:5002/api/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ identifier: username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        onLogin(data.token);
      } else {
        setError("Invalid credentials");
      }
    } catch (error) {
      setError("Something went wrong, please try again later.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md max-w-sm w-full">
        <h2 className="text-2xl font-semibold mb-4 text-center">Login</h2>
        {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            className="w-full px-3 py-2 border rounded"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            className="w-full px-3 py-2 border rounded"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button
            type="submit"
            className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

// Main App component
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [bookings, setBookings] = useState([]); 
  const [popupVisible, setPopupVisible] = useState(false); 
  const [selectedBooking, setSelectedBooking] = useState(null); 
  const { direction } = useRouteStore((state) => ({
    direction: state.direction,
  }));

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsLoggedIn(true);
      setUserInfo(decodedToken);
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.role === "admin") {
      const interval = setInterval(() => {
        fetchBookings(); 
      }, 20000); 
      return () => clearInterval(interval);
    } else if (userInfo && userInfo.role !== "admin") {
      fetchBookings();
    }
  }, [userInfo]);

  const fetchBookings = () => {
    axios.get("http://localhost:5002/api/bookings", {
      headers: {
        'Authorization': `${window.localStorage.getItem('token')}`
      }
    })
    .then(response => {
      const data = response.data;
      console.log(data, "data");
      if (data) {
        setBookings(data);
      }
    })
    .catch(error => {
      console.error("Failed to fetch bookings", error);
    });
  };

  const handleLogin = (token) => {
    const decodedToken = jwtDecode(token);
    setIsLoggedIn(true);
    setUserInfo(decodedToken);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setUserInfo(null);
  };

  const handleStatusChange = (bookingId, status) => {
    if (status === "assigned") {
      setSelectedBooking(bookingId);
      setPopupVisible(true);
    }
  };

  const handleDriverNameSubmit = (driverName) => {
    fetch(`http://localhost:5002/api/bookings/${selectedBooking}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: "assigned", driverName }),
    })
      .then(() => {
        setPopupVisible(false);
        fetchBookings(); 
      })
      .catch((error) => {
        console.error("Failed to update booking", error);
      });
  };

  // Function to extract the coordinates of each waypoint (including source and destination)
  const getRouteMarkers = () => {
    if (!direction || !direction.routes || !direction.routes[0]) return [];

    const legs = direction.routes[0].legs;
    if (!legs || legs.length === 0) return [];

    // We'll collect all points: start point of first leg, then end point of each leg.
    // Leg 0: start_location (A), end_location (B)
    // Leg 1: end_location (C)
    // Leg n: end_location (subsequent letters)
    const markers = [];
    // Start location from first leg
    const start = legs[0].start_location;
    markers.push({
      position: { lat: start.lat(), lng: start.lng() },
      label: "A"
    });

    // For each leg, take its end_location
    for (let i = 0; i < legs.length; i++) {
      const end = legs[i].end_location;
      const label = String.fromCharCode(66 + i); // 'B' for i=0, 'C' for i=1, etc.
      markers.push({
        position: { lat: end.lat(), lng: end.lng() },
        label: label
      });
    }

    return markers;
  };

  if (!isLoggedIn) {
    return (
      <div className="App bg-gray-100 min-h-screen">
        <ToastContainer />
        <LoginForm onLogin={handleLogin} />
      </div>
    );
  }

  if (isLoaded) {
    const routeMarkers = getRouteMarkers();

    return (
      <Router>
        <div className="App flex flex-col min-h-screen">
          <ToastContainer />

          {/* Header / Navbar */}
          <header className="bg-gray-800 text-white py-4 px-6 flex justify-between items-center fixed top-0 left-0 w-full z-10 shadow-md">
            <div className="flex items-center space-x-4">
              <span className="text-xl font-semibold">Welcome, {userInfo.name}</span>
              <span className="text-sm text-gray-300">({userInfo.role})</span>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex flex-col items-end">
                <span className="text-sm">Email: {userInfo.email}</span>
                <span className="text-sm">Mobile: {userInfo.mobile}</span>
              </div>
              {!userInfo || userInfo.role !== 'admin' ? (
                <Link
                  to="/your-bookings"
                  className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg transition duration-300"
                >
                  Past Bookings
                </Link>
              ) : null}
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-300"
              >
                Log Out
              </button>
            </div>
          </header>

          <div style={mapContainerStyle} className="pt-16 flex-grow">
            <Routes>
              {/* Admin Route */}
              {userInfo.role === "admin" && (
                <Route 
                  path="/" 
                  element={
                    <BookingList 
                      bookings={bookings} 
                      onStatusChange={handleStatusChange} 
                    />
                  } 
                />
              )}

              {/* Non-Admin Route (User) */}
              {userInfo.role !== "admin" && (
                <Route 
                  path="/" 
                  element={
                    <div className="relative w-full h-full flex flex-col">
                      <div className="flex-grow">
                        <GoogleMap
                          center={{ lat: 30.6561, lng: 76.729002 }}
                          zoom={15}
                          mapContainerStyle={{ width: "100%", height: "100%" }}
                          options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                        >
                          {direction && <DirectionsRenderer directions={direction} />}
                          {routeMarkers.map((marker, index) => (
                            <Marker
                              key={index}
                              position={marker.position}
                              label={marker.label}
                            />
                          ))}
                        </GoogleMap>
                      </div>
                      
                      <div className="p-4 bg-gray-100">
                        <BookingForm />
                        <Book />
                      </div>
                    </div>
                  } 
                />
              )}

              {/* YourBookings Route for Non-Admin Users */}
              {userInfo.role !== "admin" && (
                <Route 
                  path="/your-bookings" 
                  element={<YourBookings bookings={bookings} userId={userInfo.id} />} 
                />
              )}

              {/* If no match, redirect to home route */}
              <Route path="*" element={<div className="p-4">Page not found.</div>} />
            </Routes>
          </div>

          {/* Popup for assigning driver */}
          {popupVisible && (
            <Popup onSubmit={handleDriverNameSubmit} onClose={() => setPopupVisible(false)} />
          )}
        </div>
      </Router>
    );
  }

  return <div>Loading...</div>;
};

const mapContainerStyle = {
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - 4rem)", // Adjust if needed based on the header height
};

export default App;
